import { sendESputnikTrackingEventRequest } from '../../utils/api/marketingApi';

const trackProfileEvent = (profile) =>
  sendESputnikTrackingEventRequest({
    event_type: 'web funnel login success',
    email: profile.email,
    event_params: [
      {
        name: 'newsletter_sub',
        value: profile.user_settings.newsletters_enabled ? '1' : '0',
      },
      {
        name: 'name',
        value: profile.full_name,
      },
      {
        name: 'account_type',
        value: profile.type,
      },
      {
        name: 'subscription_type',
        value: profile.subscription.sku,
      },
      {
        name: 'sub_form',
        value: 'web funnel login success',
      },
    ],
  });

const trackSetPasswordEvent = (profile) =>
  sendESputnikTrackingEventRequest({
    event_type: 'web_funnel_set_password_email_verified',
    email: profile?.email,
    event_params: [
      {
        name: 'newsletter_sub',
        value: profile?.user_settings?.newsletters_enabled ? '1' : '0',
      },
      {
        name: 'name',
        value: profile?.full_name,
      },
      {
        name: 'email',
        value: profile?.email,
      },
      {
        name: 'account_type',
        value: profile?.type,
      },
      {
        name: 'subscription_type',
        value: profile?.subscription?.sku,
      },
      {
        name: 'sub_form',
        value: 'web_funnel_set_password_email_verified',
      },
      {
        name: 'confirm_email',
        value: '1',
      },
    ],
  });

export { trackProfileEvent, trackSetPasswordEvent };
