import React, { useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ReferralInstructions from './referral/ReferralInstructions';
import copyIcon from '../../../assets/icons/copy-icon.svg';
import facebookIcon from '../../../assets/icons/referral/facebook-icon.svg';
import twitterIcon from '../../../assets/icons/referral/twitter-icon.svg';
import plate0Img from '../../../assets/icons/referral/achievement/plate-0.svg';
import plate1Img from '../../../assets/icons/referral/achievement/plate-1.svg';
import plate2Img from '../../../assets/icons/referral/achievement/plate-2.svg';
import plate3Img from '../../../assets/icons/referral/achievement/plate-3.svg';
import plate4Img from '../../../assets/icons/referral/achievement/plate-4.svg';
import styles from './ReferralBlock.scss';

const achievementPlates = [plate0Img, plate1Img, plate2Img, plate3Img, plate4Img];

const anchorId = 'referral';

const ReferralBlock = ({ profile }) => {
  const inviteUrl = profile.referral.referral_url;
  const encodedInviteUrl = encodeURIComponent(inviteUrl);

  const isAffiliateBanner =
    profile.state === 'active' && profile.referral.referrals_count >= profile.referral.referrals_limit;
  const isUpgradeBanner =
    profile.state === 'free' && profile.referral.referrals_count >= profile.referral.referrals_limit;
  const isAchievementPlates = !(isAffiliateBanner || isUpgradeBanner);

  const onCopyButtonClick = () => {
    const clipboardPromise = navigator?.permissions?.query({ name: 'clipboard-write' });

    if (clipboardPromise && typeof clipboardPromise.then === 'function') {
      clipboardPromise
        .then((result) => {
          if (result.state === 'granted') {
            navigator.clipboard.writeText(inviteUrl);
          }
        })
        .catch((error) => {
          console.log('Getting clipboard permission failed:', error);
          navigator.clipboard.writeText(inviteUrl);
        });
    }
  };

  const intl = useIntl();

  const anchorRef = useRef();

  useEffect(() => {
    if (location.hash === `#${anchorId}`) {
      anchorRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <section className={styles.wrapper}>
      <h3 id={anchorId} className={styles.header} ref={anchorRef}>
        <FormattedMessage
          id="profile.referral.sectionTitle"
          defaultMessage="ClearVPN’s win-win invites"
          description="Title of the referral section"
        />
      </h3>

      <div className={styles.blockContainer}>
        <div className={styles.block}>
          <div className={styles.blockTitle}>
            <h2>
              <FormattedMessage
                id="profile.referral.title"
                defaultMessage="Invite friends and get up to 90% off"
                description="Title of the referral block"
              />
            </h2>
            <a href="https://clearvpn.com/refer-a-friend/" target="_blank" rel="noreferrer">
              <FormattedMessage
                id="profile.referral.titleLink"
                defaultMessage="Learn more"
                description="Link to the referral landing"
              />
            </a>
          </div>

          <span className={styles.shareLabel}>
            <FormattedMessage
              id="profile.referral.shareLinkLabel"
              defaultMessage="Share the referral link"
              description="Label offering use to share his referral link"
            />
          </span>

          <div className={styles.shareLine}>
            <div className={styles.linkField}>
              <span>{inviteUrl}</span>
              <button
                id="profile-copy-referral-code-button"
                title={intl.formatMessage({
                  id: 'profile.referral.copyReferralLinkButton',
                  defaultMessage: 'Copy your referral link to clipboard',
                  description: 'Hover tooltip of the button that copies user’s referral link to the clipboard',
                })}
                onClick={onCopyButtonClick}
              >
                <img src={copyIcon} width="16" height="16" id="profile-copy-referral-code-button-img" alt="" />
              </button>
            </div>

            <span className={styles.orSpan}>
              <FormattedMessage
                id="profile.referral.orLabel"
                defaultMessage="or"
                description="Label separating copying link block with share on socials"
              />
            </span>

            <div className={styles.shareButtons}>
              <a
                href={`https://twitter.com/intent/tweet?url=Check%20out%20ClearVPN%21%20A%20beautifully%20designed%20and%20powerful%20VPN%20app%20you%27d%20love%20to%20use.%20Get%20it%20now%20with%20a%2020%25%20discount%3A%20${encodedInviteUrl}`}
                target="_blank"
                rel="noreferrer"
                title={intl.formatMessage({
                  id: 'profile.referral.shareOnTwitter',
                  defaultMessage: 'Share your referral link on Twitter',
                  description: 'Hover tooltip of the "share on Twitter" link',
                })}
              >
                <img src={twitterIcon} width="24" height="24" alt="" />
              </a>

              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodedInviteUrl}`}
                target="_blank"
                rel="noreferrer"
                title={intl.formatMessage({
                  id: 'profile.referral.shareOnFacebook',
                  defaultMessage: 'Share your referral link on Facebook',
                  description: 'Hover tooltip of the "share on Facebook" link',
                })}
              >
                <img src={facebookIcon} width="24" height="24" alt="" />
              </a>
            </div>
          </div>

          <ReferralInstructions className={styles.mobileInstructions} />

          {isAffiliateBanner ? (
            <div className={styles.referralStatusCta}>
              <span>
                <FormattedMessage
                  id="profile.referral.goAffiliate.text"
                  defaultMessage="You’ve used up all 5 invites. To invite more people, go affiliate."
                  description="Banner shown to the user with maximum number of invites, promotes affiliate"
                />
              </span>
              <a href="https://clearvpn.com/affiliates/" target="_blank" rel="noreferrer">
                <FormattedMessage
                  id="profile.referral.goAffiliate.link"
                  defaultMessage="Tell me more"
                  description="Banner shown to the user with maximum number of invites, links to affiliate landing page"
                />
              </a>
            </div>
          ) : null}

          {isUpgradeBanner ? (
            <div className={styles.referralStatusCta}>
              <span>
                <FormattedMessage
                  id="profile.referral.maxDiscount.text"
                  defaultMessage="Congratulations! You have invited 5 friends and get the maximum discount!"
                  description="Banner shown to the user who got maximum discount from referral program"
                />
              </span>
              <a href={profile.referral.referral_url}>
                <FormattedMessage
                  id="profile.referral.maxDiscount.link"
                  defaultMessage="Upgrade"
                  description="Banner shown to the user who got maximum discount from referral program, links to order page"
                />
              </a>
            </div>
          ) : null}

          {isAchievementPlates ? (
            <>
              <div className={styles.achievementRow}>
                {Array.from({ length: profile.referral.referrals_limit }, (v, i) => i).map((value) => (
                  <div className={profile.referral.referrals_count > value ? styles.achieved : ''} key={value}>
                    <img src={achievementPlates[value]} width="28" height="28" alt="" />
                  </div>
                ))}
              </div>

              {profile.referral.referrals_count < profile.referral.referrals_limit && (
                <span className={styles.inviteMoreLabel}>
                  <FormattedMessage
                    id="profile.referral.inviteMoreLabel"
                    description="Label telling the user how many more friends they can invite to get the highest discount"
                    values={{ friends: profile.referral.referrals_limit - profile.referral.referrals_count }}
                    defaultMessage={`Invite {friends, plural,
                              one {# more friend}
                              few {# more friends}
                              other {# more friends}
                          }`}
                  />
                </span>
              )}
            </>
          ) : null}
        </div>

        <ReferralInstructions className={styles.desktopInstructions} />
      </div>
    </section>
  );
};

export default ReferralBlock;
