import React, { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button } from '../../common/Button';
import { logError } from '../../../services/errorReporting';
import { isApiError } from '../../../utils/api/clearApiClient';
import { logOTCEmailSubmit } from '../../../utils/analytics/authEvents';
import ReCaptchaDisclaimer from '../fields/reCaptchaDisclaimer';
import { genericErrorMessage } from '../../../utils/lang/commonErrors';
import SocialButton from './components/SocialButton';
import TextInput from './components/TextInput';
import styles from './AuthForm.scss';

interface EmailSignUpFormProps {
  sendOTCRequest: (emailAdress: string) => Promise<unknown>;
  onSubmit: (email: string) => void;
  presetEmail?: string;
}

const EmailSignUpForm = ({ presetEmail, sendOTCRequest, onSubmit }: EmailSignUpFormProps) => {
  const intl = useIntl();

  const [email, setEmail] = useState(presetEmail || '');
  const [error, setError] = useState('');
  const [isValid, setValid] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);

  const onEmailChange = (event: { value: string; isValid: boolean }) => {
    setEmail(event.value);
    setValid(event.isValid);
    setError('');
  };

  const validateEmail = () => {
    if (email.length === 0) {
      setError(
        intl.formatMessage({
          id: 'authScreen.emailSignup.errors.noEmail',
          defaultMessage: 'Email is required',
          description: 'Error shown when submitting email form w/o email',
        }),
      );

      return false;
    }
    if (!isValid) {
      setError(
        intl.formatMessage({
          id: 'authScreen.emailSignup.errors.invalidEmail',
          defaultMessage: 'Email is invalid',
          description: 'Error shown when submitting email of invalid format',
        }),
      );

      return false;
    }

    return true;
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    if (validateEmail()) {
      setSubmitting(true);
      sendOTCRequest(email).then(
        () => {
          onSubmit(email);
          setSubmitting(false);
          logOTCEmailSubmit();
        },
        (error_: unknown) => {
          logError(isApiError(error_) ? error_ : new Error(String(error_)));
          setSubmitting(false);
          setError(intl.formatMessage(genericErrorMessage));
        },
      );
    }
  };

  return (
    <div className={styles.wrapper}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <h3 className={styles.title}>
          <FormattedMessage
            id="authScreen.emailSignup.title"
            defaultMessage="Sweet, let’s get you started"
            description="Title of email form at the start of the sign up flow"
          />
        </h3>
        <TextInput
          value={email}
          handleChange={onEmailChange}
          error={error}
          type="email"
          autoComplete="email"
          disabled={isSubmitting}
          placeholder={intl.formatMessage({
            id: 'authScreen.emailSignUp.emailInputPlaceholder',
            defaultMessage: 'Enter your email',
            description: 'Placeholder of the email input field',
          })}
          onBlur={validateEmail}
        />

        <Button type="submit" disabled={isSubmitting}>
          <FormattedMessage
            id="authScreen.emailSignup.submitButton"
            defaultMessage="Continue"
            description="Email sign up form submit button text"
          />
        </Button>

        <span className={styles.socialButtonsLabel}>
          <FormattedMessage
            id="authScreen.emailSignup.useSocialsLabel"
            defaultMessage="or sign in with"
            description="Label between email sign up form and section with social logins"
          />
        </span>

        <div className={styles.socialButtonsWrapper}>
          <SocialButton type="google" />
          <SocialButton type="apple" />
          <SocialButton type="setapp" />
        </div>
      </form>

      <ReCaptchaDisclaimer />
    </div>
  );
};

export default EmailSignUpForm;
