import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useIntl, FormattedMessage } from 'react-intl';
import * as profileParser from '../../utils/billingInfoParser';
import { rootElementOptions } from '../../utils/payments/fieldComponentOptions';
import PaymentMethod from './BillingInfo/PaymentMethod';
import PaymentIssue from './BillingInfo/PaymentIssue';
import PayPalDeprecationBanner from './BillingInfo/PayPalDeprecationBanner';
import UnsubscribeDialog from './floating/UnsubscribeDialog';
import MobileStoreUnsubscribeDialog from './floating/MobileStoreUnsubscribeDialog';
import ChangeMethodDialog from './floating/ChangeMethodDialog';
import RenewDialog from './floating/RenewDialog';
import moneyEmojiImage from '../../../assets/icons/referral/money-with-wings-emoji.png';
import moneyEmojiImage2x from '../../../assets/icons/referral/money-with-wings-emoji@2x.png';
import styles from './BillingInfo.scss';

const stripePromise = loadStripe(process.env.STRIPE_KEY).catch((error) =>
  console.error("Couldn't load Stripe:", error),
);

// eslint-disable-next-line sonarjs/cognitive-complexity -- :pensive:
const BillingInfo = ({ profile, premiumLink, coupon }) => {
  const intl = useIntl();

  const planName = profileParser.resolvePlanName(profile, intl);
  const specialPeriodBubble = profileParser.resolveSpecialPeriodBubble(profile, intl);
  const { isUpgradeCta, isRenewCta, isCancelCta, isMobileStoreCancelDialog, isSetappManageSubCta } =
    profileParser.resolveMainButton(profile);
  const { isDateShown, dateLabel, dateValue } = profileParser.resolveDateLine(profile, intl);
  const isPromoRedeemShown = profileParser.resolvePromocodeRedeem(profile, coupon, intl);
  const { isDiscountBannerShown, discountBannerText } = profileParser.resolveDiscountBanner(profile, coupon, intl);
  const { isPaymentMethodShown, isMethodEditable, hasPaymentIssue } = profileParser.resolvePaymentMethod(profile);
  const hasPayPalWarning = profileParser.hasPayPalDeprecationWarning(profile);

  const [isCancelDialogShow, setCancelDialogShown] = useState(false);
  const showUnsubscribeDialog = () => setCancelDialogShown(true);
  const onCancelUnsubscribe = () => setCancelDialogShown(false);

  const [isChangeMethodDialogShown, setChangeMethodDialogShown] = useState(false);
  const showChangeMethodDialog = () => setChangeMethodDialogShown(true);
  const hideChangeMethodDialog = () => setChangeMethodDialogShown(false);

  const [isRenewDialogShown, setRenewDialogShown] = useState(false);
  const onCancelRenew = () => setRenewDialogShown(false);

  return (
    <>
      {hasPayPalWarning ? <PayPalDeprecationBanner onClick={() => setChangeMethodDialogShown(true)} /> : null}

      <section className={styles.section}>
        <div className={styles.block}>
          <span className={styles.currentPlanLabel}>
            <FormattedMessage
              id="profile.billing.currentPlanLabel"
              defaultMessage="Current plan"
              description="Labels the current plan info"
            />
          </span>

          <div className={styles.firstLine}>
            <div className={styles.firstLineLeft}>
              <span className={styles.planName}>{planName}</span>
              {specialPeriodBubble.isShown ? (
                <span
                  className={`${styles.trialBubble} ${
                    specialPeriodBubble.tooltip ? styles.trialBubbleWithTooltip : ''
                  }`}
                >
                  {specialPeriodBubble.text}
                  {specialPeriodBubble.tooltip ? (
                    <span className={styles.tooltip}>{specialPeriodBubble.tooltip}</span>
                  ) : null}
                </span>
              ) : null}
            </div>

            <div className={styles.firstLineRight}>
              {isUpgradeCta ? (
                <a href={premiumLink} className={styles.coloredButton}>
                  <FormattedMessage
                    id="profile.billing.upgradePlan"
                    defaultMessage="Upgrade plan"
                    description="Button that allows user to switch plan, i.e. from Monthly to Annual"
                  />
                </a>
              ) : null}
              {isRenewCta ? (
                <button className={styles.coloredButton} onClick={() => setRenewDialogShown(true)}>
                  <FormattedMessage
                    id="profile.billing.renewSubscription"
                    defaultMessage="Renew subscription"
                    description="Button for re-purchasing an already canceled subscription"
                  />
                </button>
              ) : null}
              {isCancelCta ? (
                <button className={styles.greyButton} onClick={showUnsubscribeDialog}>
                  <FormattedMessage
                    id="profile.billing.cancelSubscription"
                    defaultMessage="Cancel subscription"
                    description="Button to cancel current subscription"
                  />
                </button>
              ) : null}
              {isSetappManageSubCta ? (
                <a
                  href="https://my.setapp.com/subscription"
                  className={styles.greyButton}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FormattedMessage
                    id="profile.billing.manageSetAppSubscription"
                    defaultMessage="Manage subscription"
                    description="Link to SetApp user’s cabinet, shown only for users with SetApp subscription"
                  />
                </a>
              ) : null}
            </div>
          </div>

          {isDateShown ? (
            <div className={styles.dateLine}>
              <span className={styles.dateLineLabel}>{dateLabel}</span>
              <span className={styles.dateLineValue}>{dateValue}</span>
            </div>
          ) : null}

          {isPromoRedeemShown ? (
            <div className={styles.redeemLine}>
              <a href="https://clearvpn.com/redeem/" className={styles.greyButton}>
                <FormattedMessage
                  id="profile.billing.redeemPromoCode"
                  defaultMessage="Redeem a promocode"
                  description="Link to a promo code redeem page"
                />
              </a>
            </div>
          ) : null}

          {isDiscountBannerShown ? (
            <div className={styles.referralBanner}>
              <img
                src={moneyEmojiImage}
                srcSet={`${moneyEmojiImage2x} 2x`}
                width="24"
                height="24"
                alt="Money with wings emoji"
              />
              <span>{discountBannerText}</span>
            </div>
          ) : null}

          {isPaymentMethodShown ? (
            <>
              <div className={styles.divider} />

              <div className={styles.paymentMethod}>
                <h3 className={styles.paymentMethodTitle}>
                  <FormattedMessage
                    id="profile.billing.paymentMethodSubtitle"
                    defaultMessage="Payment method"
                    description="Title of the section displaying payment method, i.e. card, PayPal..."
                  />
                </h3>

                <div className={styles.methodInfo}>
                  <PaymentMethod paymentMethod={profile.payment_data.payment_method} />

                  {isMethodEditable ? (
                    <button className={styles.changeButton} onClick={showChangeMethodDialog}>
                      <FormattedMessage
                        id="profile.billing.changePaymentMethodButton"
                        defaultMessage="Change"
                        description="Button that allows to change payment method (card, PayPal, ...) for the next charge"
                      />
                    </button>
                  ) : null}
                </div>
              </div>

              {hasPaymentIssue ? <PaymentIssue /> : null}
            </>
          ) : null}
        </div>
      </section>

      <UnsubscribeDialog
        profile={profile}
        visible={isCancelDialogShow ? !isMobileStoreCancelDialog : null}
        onCancel={onCancelUnsubscribe}
      />

      {isCancelDialogShow && isMobileStoreCancelDialog ? (
        <MobileStoreUnsubscribeDialog onCloseClick={onCancelUnsubscribe} />
      ) : null}
      {isMethodEditable || hasPayPalWarning ? (
        <Elements stripe={stripePromise} options={rootElementOptions}>
          <ChangeMethodDialog
            profile={profile}
            isDialogShown={isChangeMethodDialogShown}
            onCancel={hideChangeMethodDialog}
          />
        </Elements>
      ) : null}

      <RenewDialog profile={profile} isDialogShown={isRenewDialogShown} onClose={onCancelRenew} />
    </>
  );
};

export default BillingInfo;
