import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import { useState } from 'react';
import { Button } from '../common/Button';
import DeleteAccountModal from './floating/DeleteAccountModal';
import styles from './Notifications.scss';

const AccountManagement = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <section className={styles.section}>
      <h3 className={styles.title}>
        <FormattedMessage id="profile.manageAccount.title" defaultMessage="Manage Account" />
      </h3>

      <div className={styles.container}>
        <article className={clsx(styles.block, styles.deleteBlock)}>
          <div>
            <h4 className={styles.blockTitle}>
              <FormattedMessage id="profile.manageAccount.deleteAccountTitle" defaultMessage="Delete account" />
            </h4>

            <p className={styles.blockDescription}>
              <FormattedMessage
                id="profile.manageAccount.deleteAccountDescription"
                defaultMessage="You won't be able to use our app, but your subscription will remain active. You can restore it within 30 days if you decide to come back"
              />
            </p>
          </div>

          <Button
            kind="primary"
            type="button"
            className={styles.deleteButton}
            onClick={() => {
              setModalOpen(true);
            }}
          >
            <FormattedMessage id="profile.manageAccount.deleteAccountButton" defaultMessage="Delete" />
          </Button>
        </article>
      </div>

      <DeleteAccountModal
        isOpen={isModalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      />
    </section>
  );
};

export default AccountManagement;
