import React from 'react';
import { useLocale } from '../lang/LocalizationProvider';
import dropDownArrowImage from '../../../assets/icons/localization/dropdown-arrow.svg';
import styles from './LocalizationSelect.scss';

const localeToLabel = {
  en: 'English',
  uk: 'Українська',
  de: 'Deutsch',
  fr: 'Français',
  es: 'Español',
};

const LocalizationSelect = ({ className = '' }) => {
  const { selectedLocalization, availableLocalizations, changeLocalization } = useLocale();

  const onChange = (e) => changeLocalization(e.target.value);

  return (
    <label className={`${styles.label} ${className}`}>
      {selectedLocalization.toUpperCase()}
      <img src={dropDownArrowImage} width="24" height="24" alt="" />

      <select
        name="Localization"
        id="localization-select"
        value={selectedLocalization}
        className={styles.select}
        onChange={onChange}
      >
        {availableLocalizations.map((locale) => (
          <option value={locale} key={locale}>
            {localeToLabel[locale]}
          </option>
        ))}
      </select>
    </label>
  );
};

export default LocalizationSelect;
