import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './Footer.scss';

const Footer = ({ className = '' }) => (
  <footer className={`${styles.container} ${className}`}>
    <ul className={styles.links}>
      <li>
        <a href="https://clearvpn.com/privacy-policy" target="_blank" rel="noreferrer">
          <FormattedMessage
            id="layout.footer.privacyPolicyLink"
            defaultMessage="Privacy Policy"
            description="Footer link to Privacy Policy page"
          />
        </a>
      </li>
      <li>
        <a href="https://clearvpn.com/terms-of-service" target="_blank" rel="noreferrer">
          <FormattedMessage
            id="layout.footer.termsAndConditionsLink"
            defaultMessage="Terms & Conditions"
            description="Footer link to Terms & Conditions page"
          />
        </a>
      </li>
      <li>
        <a href="https://support.clearvpn.com/" target="_blank" rel="noreferrer">
          <FormattedMessage
            id="layout.footer.supportLink"
            defaultMessage="Support"
            description="Footer link to Support portal"
          />
        </a>
      </li>
    </ul>

    <span>&#169; 2024 ClearVPN</span>
  </footer>
);

export default Footer;
