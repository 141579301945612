import { sendBindReferralRequest } from './api/accountApi';

const referralCodeLocalStorageKey = 'referralCode';

const saveReferralCode = (code) => localStorage.setItem(referralCodeLocalStorageKey, code);

const registerReferralCode = () => {
  const savedCode = localStorage.getItem(referralCodeLocalStorageKey);

  if (!savedCode) {
    return Promise.resolve();
  }

  return sendBindReferralRequest(savedCode, { shouldDropToLogin: false })
    .then(() => {
      console.log('Referral code applied');
      localStorage.removeItem(referralCodeLocalStorageKey);
    })
    .catch((error) => {
      console.error('Could not bind referral code to account:', error);
    });
};

export { saveReferralCode, registerReferralCode };
