import React, { useState } from 'react';
import t from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import Checkbox from '../Enter/fields/Checkbox';
import { sendUpdateSettingsRequest } from '../../utils/api/accountApi';
import styles from './Notifications.scss';

const Notifications = ({ userSettings }) => {
  const intl = useIntl();
  const { newsletters_enabled: newslettersEnabled } = userSettings;

  const [isEnabled, setEnabled] = useState(newslettersEnabled);
  const [isLoading, setLoading] = useState(false);

  const updateNewsletterSetting = (newValue) => {
    setLoading(true);
    sendUpdateSettingsRequest().then(() => {
      setLoading(false);
      setEnabled(newValue);
    });
  };

  return (
    <section className={styles.section}>
      <h3 className={styles.title}>
        <FormattedMessage
          id="profile.notifications.title"
          defaultMessage="Notifications"
          description="Title of the notification settings section"
        />
      </h3>

      <div className={styles.container}>
        <article className={styles.block}>
          <h4 className={styles.blockTitle}>
            <FormattedMessage
              id="profile.notifications.newsletterLabel"
              defaultMessage="Newsletter"
              description="Label of the newsletter subscription checkbox"
            />
          </h4>
          <Checkbox
            largeLabel
            isNoError
            name="subscription"
            value={isEnabled}
            isLoading={isLoading}
            className={styles.checkbox}
            label={intl.formatMessage({
              id: 'profile.notifications.newsletterDescription',
              defaultMessage: 'Important updates, tips & offers',
              description: 'Description of the newsletter subscription checkbox',
            })}
            onChange={updateNewsletterSetting}
          />
        </article>
      </div>
    </section>
  );
};

Notifications.propTypes = {
  userSettings: t.shape({
    newsletters_enabled: t.bool.isRequired,
  }),
};

export default Notifications;
