import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import AlertIcon from '../../../../assets/icons/alert-exclamation.svg';
import CloseBubbleIcon from '../../../../assets/icons/close-dialog-icon-light.svg';
import styles from './FailedRenewBubble.scss';

const FailedRenewBubble = () => {
  const [isClosed, setIsClosed] = useState(false);

  return isClosed ? null : (
    <div className={styles.container}>
      <img src={AlertIcon} alt="" className={styles.alertIcon} />
      <span className={styles.text}>
        <FormattedMessage
          id="profile.failedRenewBubble"
          defaultMessage="We have been unable to charge you for the Premium subscription. Please, check your payment details."
          description="Little banner shown to user, if their subscription renewal failed"
        />
      </span>
      <button className={styles.closeButton} onClick={() => setIsClosed(true)}>
        <img src={CloseBubbleIcon} alt="" />
      </button>
    </div>
  );
};

export default FailedRenewBubble;
