import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '../../common/Button';
import { lockBackgroundScroll, unlockBackgroundScroll } from '../../../utils/lockBackgroundScroll';
import { sendAccountDeletionRequest } from '../../../utils/api/accountApi';
import { getRefreshToken, redirectToAuth, removeTokens } from '../../../utils/auth';
import { genericErrorMessage } from '../../../utils/lang/commonErrors';
import hooverNoImage from '../../../../assets/icons/account-termination/hoover-no.svg';
import styles from './DeleteAccountModal.scss';

interface DeleteAccountModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const DeleteAccountModal = ({ isOpen, onClose }: DeleteAccountModalProps) => {
  const intl = useIntl();

  const [isLoading, setLoading] = useState(false);

  const [error, setError] = useState<string | null>(null);

  const handleClose = () => {
    if (!isLoading) {
      onClose();
    }
  };

  const onConfirmClick = () => {
    setLoading(true);
    setError(null);

    const refreshToken = getRefreshToken();

    if (!refreshToken) {
      removeTokens();
      redirectToAuth();

      return;
    }

    sendAccountDeletionRequest(refreshToken)
      .then(
        () => {
          removeTokens();
          redirectToAuth();
        },
        () => {
          setError(intl.formatMessage(genericErrorMessage));
        },
      )
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (isOpen) {
      lockBackgroundScroll();
    } else {
      unlockBackgroundScroll();
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions -- modal overlay instinctive click
    <div className={styles.overlay} onClick={handleClose}>
      <div className={`${styles.dialog} ${error ? styles.dialogWithError : ''}`}>
        <img src={hooverNoImage} width="196" height="147" alt="" className={styles.hooverImg} />

        <h3 className={styles.title}>
          <FormattedMessage
            id="profile.deleteAccountModal.title"
            defaultMessage="Do you want to delete your account?"
          />
        </h3>

        <p className={styles.description}>
          <FormattedMessage
            id="profile.deleteAccountModal.description"
            defaultMessage="You won't be able to use our app, but your subscription will remain active. You can restore it within 30 days if you decide to come back"
          />
        </p>

        <div className={styles.buttonsLine}>
          <Button className={styles.goBackButton} kind="secondary" disabled={isLoading} onClick={handleClose}>
            <FormattedMessage id="profile.deleteAccountModal.cancelButton" defaultMessage="Cancel" />
          </Button>

          <Button className={styles.cancelButton} loading={isLoading} disabled={isLoading} onClick={onConfirmClick}>
            <FormattedMessage id="profile.deleteAccountModal.deleteAccountButton" defaultMessage="Delete account" />
          </Button>
        </div>

        {error ? <span className={styles.error}>{error}</span> : null}
      </div>
    </div>
  );
};

export default DeleteAccountModal;
