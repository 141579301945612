import React, { useEffect, useState, useRef } from 'react';
import QRCode from 'qrcode.react';
import { FormattedMessage, useIntl } from 'react-intl';
import { lockBackgroundScroll, unlockBackgroundScroll } from '../../../utils/lockBackgroundScroll';
import { sendProfileRequest } from '../../../utils/api/profileApi';
import { sendDiiaDeepLinkRequest } from '../../../utils/api/accountApi';
import { createTimer } from '../../../utils/timer';
import { isDiiaUser } from '../../../utils/billingInfoParser';
import { logDiiaQRShown, logDiiaSuccess } from '../../../utils/analytics/diiaEvents';
import { browserDeeplink } from '../../../../lib/browser-deeplink';
import { genericErrorMessage } from '../../../utils/lang/commonErrors';
import { isMobile } from '../../../utils/platformResolver';
import Loader from '../../common/Loader';
import closeDialogIcon from '../../../../assets/icons/close-dialog-icon.svg';
import successHighFiveImage from '../../../../assets/icons/diia/high-five.svg';
import styles from './DiiaModal.scss';

const DIIA_TOKEN_EXPIRATION_SECONDS = 180;

interface DiiaModalProps {
  isOpen: boolean;
  onClose: () => void;
  successRedirectURL?: string;
}

const buttonOptions = {
  NONE: 'none',
  LINK: 'link',
  BUTTON: 'BUTTON',
} as const;

// fight the temptation to call it a `Diialog`
const DiiaModal = ({ isOpen, onClose, successRedirectURL }: DiiaModalProps) => {
  const intl = useIntl();

  const [isLoading, setLoading] = useState(true);

  const timer = useRef<{ clearTimer: () => void }>();
  const [countdownValue, setCountdownValue] = useState('');
  const onTick = (seconds: number) => {
    setCountdownValue(`${Math.floor(seconds / 60)}:${(seconds % 60).toString().padStart(2, '0')}`);
  };

  const [diiaDeepLink, setDiiaDeeplink] = useState('');
  const [error, setError] = useState('');

  const [buttonOption, setButtonOption] = useState<(typeof buttonOptions)[keyof typeof buttonOptions]>(
    buttonOptions.NONE,
  );

  useEffect(() => {
    const fetchDiiaLink = () => {
      setError('');
      setLoading(true);

      sendDiiaDeepLinkRequest()
        .then((res) => {
          setDiiaDeeplink(res.deep_link);
          logDiiaQRShown();
          timer.current = createTimer(DIIA_TOKEN_EXPIRATION_SECONDS, onTick, fetchDiiaLink);
          onTick(DIIA_TOKEN_EXPIRATION_SECONDS);
          setLoading(false);
        })
        .catch((error_: unknown) => {
          console.error('Could’t fetch Diia deeplink:', error_);
          setError(intl.formatMessage(genericErrorMessage));
        });
    };

    if (isOpen) {
      lockBackgroundScroll();
      fetchDiiaLink();
    } else {
      unlockBackgroundScroll();
      if (timer.current?.clearTimer) {
        timer.current.clearTimer();
      }
    }

    if (!isMobile()) {
      setButtonOption(buttonOptions.BUTTON);
    } else if (window.location.search.includes('platform=ipad')) {
      setButtonOption(buttonOptions.LINK);
    }
  }, [isOpen]);

  const [isSuccess, setSuccess] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isOpen && !isLoading && !isSuccess && !error) {
        sendProfileRequest(true).then((data) => {
          if (isDiiaUser(data.profile)) {
            logDiiaSuccess();
            setSuccess(true);
            clearInterval(interval);
          }
        });
      }
    }, 10_000);

    return () => {
      clearInterval(interval);
    };
  }, [isOpen, isLoading]);

  const onCloseClick = () => {
    if (isSuccess) {
      location.reload();
    }
    onClose();
  };

  const onContinueButtonClick = () => {
    if (successRedirectURL) {
      browserDeeplink(successRedirectURL).catch((error_: unknown) => {
        console.error('Failed to open Diia success redirect URL', error_);
      });
    } else {
      location.reload();
    }
  };

  let content;

  if (isSuccess) {
    content = (
      <>
        <h2 className={styles.dialogTitle}>
          <FormattedMessage
            id="profile.diia.modal.successTitle"
            defaultMessage="You passed the 'palianytsia' test!"
            description="Title of the Diia modal, once the citizenship has been verified"
          />
        </h2>
        <span className={styles.dialogSubtitle}>
          <FormattedMessage
            id="profile.diia.modal.successSubtitle"
            defaultMessage="Glory to Ukraine! Welcome to the family."
            description="Subtitle of the Diia modal, once the citizenship has been verified"
          />
        </span>

        <img src={successHighFiveImage} width="270" height="240" alt="" />

        {buttonOption === buttonOptions.BUTTON && (
          <button className={styles.cta} onClick={onContinueButtonClick}>
            <FormattedMessage
              id="profile.diia.modal.successCTA"
              defaultMessage="Continue"
              description="Button of the Diia modal, once the citizenship has been verified, leads to app or refreshes the profile"
            />
          </button>
        )}

        {buttonOption === buttonOptions.LINK && (
          <a href={successRedirectURL} className={styles.cta}>
            <FormattedMessage
              id="profile.diia.modal.successCTA"
              defaultMessage="Continue"
              description="Button of the Diia modal, once the citizenship has been verified, leads to app or refreshes the profile"
            />
          </a>
        )}
      </>
    );
  } else if (error) {
    content = <span className={styles.error}>{error}</span>;
  } else if (isLoading) {
    content = <Loader colored centered />;
  } else {
    content = (
      <>
        <div className={styles.qr}>
          <QRCode value={diiaDeepLink} size={220} />
        </div>

        <span className={styles.timer}>{countdownValue}</span>
      </>
    );
  }

  return isOpen ? (
    <div className={styles.overlay}>
      <div className={styles.dialog}>
        <button className={styles.closeDialogButton} onClick={onCloseClick}>
          <img src={closeDialogIcon} alt="Close pop-up button" width="14" height="14" />
        </button>

        {!isSuccess && (
          <>
            <h2 className={styles.dialogTitle}>
              <FormattedMessage
                id="profile.diia.modal.pendingTitle"
                defaultMessage="Citizenship verification with Diia"
                description="Title of the Diia modal, shown with the QR code to scan"
              />
            </h2>
            <span className={styles.dialogSubtitle}>
              <FormattedMessage
                id="profile.diia.modal.pendingSubtitle"
                defaultMessage="Scan this QR code in the Diia app"
                description="Subtitle of the Diia modal, shown with the QR code to scan"
              />
            </span>
          </>
        )}

        {content}
      </div>
    </div>
  ) : null;
};

export default DiiaModal;
