import ProfileLayout from '../../components/layout/ProfileLayout';
import ProfileContainer from '../../components/Profile/ProfileContainer';

const Profile = () => (
  <ProfileLayout>
    <ProfileContainer />
  </ProfileLayout>
);

export default Profile;
