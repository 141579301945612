import React from 'react';
import t from 'prop-types';
import { FormattedMessage } from 'react-intl';
import alertExclamationIcon from '../../../../assets/icons/alert-icon.svg';
import closeDialogIcon from '../../../../assets/icons/close-dialog-icon.svg';
import noteIcon from '../../../../assets/icons/note-icon.svg';
import styles from './UnsubscribeDialogs.scss';

const MobileStoreUnsubscribeDialog = ({ onCloseClick }) => (
  <div className={styles.overlay}>
    <div className={styles.dialog}>
      <button className={styles.closeDialogButton} onClick={onCloseClick}>
        <img src={closeDialogIcon} width="14" height="14" alt="" />
      </button>

      <img src={alertExclamationIcon} className={styles.alertIcon} width="48" height="48" alt="" />

      <h3 className={styles.title}>
        <FormattedMessage
          id="profile.billing.mobileStoreCancelModal.title"
          defaultMessage="Are you sure you want to cancel your subscription?"
          description="Mobile store subscription cancel modal title"
        />
      </h3>

      <div className={styles.subtitle}>
        <h3>
          <FormattedMessage
            id="profile.billing.mobileStoreCancelModal.subtitle"
            defaultMessage="Users whose subscriptions are billed as an in-app purchase through a digital marketplace (App Store, Google Play, etc.) can cancel subscription only using subscription settings on device."
            description="Mobile store subscription cancel modal subtitle"
          />
        </h3>
      </div>

      <div className={styles.note}>
        <img src={noteIcon} alt="" width="24" height="24" />
        <p className={styles.noteText}>
          <FormattedMessage
            id="profile.billing.mobileStoreCancelModal.noteText"
            defaultMessage="Note, in-app subscription that has already been cancelled won’t be renewed again."
            description="Mobile store subscription cancel modal note text"
          />
        </p>
      </div>

      <div className={styles.buttonWrapper}>
        <a
          href="https://support.clearvpn.com/hc/en-us/articles/360017584659-How-do-I-cancel-my-subscription-"
          target="_blank"
          rel="noreferrer"
          className={styles.ctaLink}
        >
          <FormattedMessage
            id="profile.billing.mobileStoreCancelModal.faqLink"
            defaultMessage="Visit FAQ page"
            description="Mobile store subscription cancel modal link to FAQ"
          />
        </a>
      </div>
    </div>
  </div>
);

MobileStoreUnsubscribeDialog.propTypes = {
  onCloseClick: t.func.isRequired,
};

export default MobileStoreUnsubscribeDialog;
