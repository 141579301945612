import React, { useEffect, useState, type ReactNode } from 'react';
import clsx from 'clsx';
import Loader from '../../common/Loader';
import { useDownloadLinks } from '../DownloadLinksContext';
import { browserDeeplink } from '../../../../lib/browser-deeplink';
import { isMacOS, isMobile, isWindows } from '../../../utils/platformResolver';
import { logDownloadStarted } from '../../../utils/analytics/downloadEvents';
import styles from '../DeviceManagement.scss';

interface LinkCurrentDeviceButtonProps {
  children: ReactNode;
  refreshDownloadToken: () => Promise<void>;
  downloadToken?: string;
  className?: string;
}

const LinkCurrentDeviceButton = ({
  children,
  downloadToken,
  refreshDownloadToken,
  className = '',
}: LinkCurrentDeviceButtonProps) => {
  const [appLink, setAppLink] = useState('/');
  const [isMobileLink] = useState(isMobile());

  const downloadLinks = useDownloadLinks();

  useEffect(() => {
    if (isMobile()) {
      setAppLink(`https://clearvpn.onelink.me/uPP4/1q2xto27?deep_link_sub1=${downloadToken}`);
    } else {
      setAppLink(`clearvpn://go/register?permanent_token=${downloadToken}`);
    }
  }, [downloadToken]);
  const onClick = () => {
    if (!appLink) {
      return;
    }
    if (isMobileLink) {
      refreshDownloadToken();

      return;
    }

    browserDeeplink(appLink)
      .catch(() => {
        if (isMacOS()) {
          logDownloadStarted('macos');
          location.href = downloadLinks.macos;
        } else if (isWindows()) {
          logDownloadStarted('windows');
          location.href = downloadLinks.windows;
        }
      })
      .then(refreshDownloadToken);
  };

  return (
    <a
      href={isMobileLink ? appLink : undefined}
      className={clsx(styles.autoLoginSectionButton, className)}
      onClick={onClick}
    >
      {appLink ? children : <Loader />}
    </a>
  );
};

export default LinkCurrentDeviceButton;
