import React, { useState, useEffect, useRef } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { constructSniperLink } from '../../../utils/emailSniperLinks';
import { isApiError } from '../../../utils/api/clearApiClient';
import { sendOneTimeCodeVerifyRequest } from '../../../utils/api/accountApi';
import { httpResponseCodes } from '../../../utils/consts/apiCodes';
import { Button } from '../../common/Button';
import { getParsedParams } from '../../../utils/consts/params';
import { logOTCEmailResend } from '../../../utils/analytics/authEvents';
import ReCaptchaDisclaimer from '../fields/reCaptchaDisclaimer';
import { genericErrorMessage } from '../../../utils/lang/commonErrors';
import InputError from './components/InputError';
import BackButton from './components/BackButton';
import styles from './AuthForm.scss';

const initialCountdownTimeSeconds = 59;
const intervalMills = 1000;
const secondsStringLength = 2;

const codeLength = 6;

let interval: NodeJS.Timeout;
const launchCountDownTimer = (
  setCountdownResetTimer: React.Dispatch<React.SetStateAction<number>>,
  setResetButtonEnabled: (enabled: boolean) => void,
) => {
  interval = setInterval(() => {
    setCountdownResetTimer((value) => {
      if (value <= 1) {
        clearInterval(interval);
        setResetButtonEnabled(true);

        return 0;
      }

      return value - 1;
    });
  }, intervalMills);
};

interface OneTimeCodeFormProps {
  email: string;
  sendOTCRequest: (emailAdress: string) => Promise<unknown>;
  onBackButtonClick: () => void;
  onAuthSuccess: () => void;
}

const OneTimeCodeForm = ({ email, sendOTCRequest, onBackButtonClick, onAuthSuccess }: OneTimeCodeFormProps) => {
  const intl = useIntl();

  const codeInputRef = useRef<HTMLInputElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const sniperLink = constructSniperLink(email);

  const [error, setError] = useState<string>('');
  const [isSubmitting, setSubmitting] = useState(false);

  const [countdownResetTimer, setCountdownResetTimer] = useState(initialCountdownTimeSeconds);
  const [isResetButtonEnabled, setResetButtonEnabled] = useState(false);
  const [isResendButtonLoading, setResendButtonLoading] = useState(false);

  const onCodeChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.value.length === codeLength) {
      submitButtonRef.current?.focus();
    }
  };

  const onSubmit = (e?: React.SyntheticEvent) => {
    e?.preventDefault();

    const code = codeInputRef.current?.value;
    const isValid = code && codeInputRef.current.validity.valid;

    if (isValid) {
      setSubmitting(true);
      setError('');
      sendOneTimeCodeVerifyRequest(email, code)
        .then(onAuthSuccess)
        .catch((apiError: unknown) => {
          console.log(apiError);
          if (isApiError(apiError)) {
            if (
              apiError.response?.status === httpResponseCodes.BAD_REQUEST &&
              apiError.response.data.message === 'AccountDeleted'
            ) {
              /*
               this includes apiError.response?.data.message === 'AccountDeleted' condition
               this status will only be temporal, untill the job on the backend deletes the account properly
              */
              setError(intl.formatMessage(genericErrorMessage));
            } else if (apiError.response?.status === httpResponseCodes.BAD_REQUEST) {
              setError(intl.formatMessage({ id: 'authScreen.otcForm.errors.wrongCode', defaultMessage: 'Wrong code' }));
            } else {
              setError(intl.formatMessage(genericErrorMessage));
            }
          } else {
            setError(intl.formatMessage(genericErrorMessage));
          }
          setSubmitting(false);
        });
    }
  };

  const onResendEmailClick = () => {
    setResendButtonLoading(true);

    sendOTCRequest(email).then(() => {
      setResetButtonEnabled(false);
      setCountdownResetTimer(initialCountdownTimeSeconds);
      launchCountDownTimer(setCountdownResetTimer, setResetButtonEnabled);

      setResendButtonLoading(false);
    });
    logOTCEmailResend();
  };

  useEffect(() => {
    launchCountDownTimer(setCountdownResetTimer, setResetButtonEnabled);
    codeInputRef.current?.focus();

    const params = getParsedParams();

    if (params.OTC_CODE && codeInputRef.current) {
      codeInputRef.current.value = params.OTC_CODE;
      onSubmit();
    }
  }, []);

  return (
    <div className={styles.wrapper}>
      <form className={styles.form} onSubmit={onSubmit}>
        <BackButton onClick={onBackButtonClick} />
        <h3 className={styles.title}>
          <FormattedMessage id="authScreen.otcForm.title" defaultMessage="Enter the verification code" />
        </h3>
        <span className={styles.subtitle}>
          <FormattedMessage
            id="authScreen.otcForm.subtitle"
            defaultMessage="Check your inbox and sign in with the magic link or paste the activation 6-digit activation code below"
          />
          <br />
          <b>{email}</b>
          {sniperLink ? (
            <a className={styles.subtitleButton} href={sniperLink}>
              <FormattedMessage
                id="authScreen.otcForm.sniperLink"
                defaultMessage="Open inbox"
                description="Link that will magically open user's email client with our email highlighted"
              />
            </a>
          ) : null}
        </span>

        <div className={styles.otcWrapper}>
          <input
            type="text"
            autoComplete="one-time-code"
            inputMode="numeric"
            minLength={codeLength}
            maxLength={codeLength}
            pattern="\d{6}"
            className={styles.otcInput}
            disabled={isSubmitting}
            ref={codeInputRef}
            onChange={onCodeChange}
          />
        </div>
        {error ? <InputError error={error} /> : null}

        <Button type="submit" kind="primary" ref={submitButtonRef} loading={isSubmitting}>
          <FormattedMessage
            id="authScreen.otcForm.submitCode"
            defaultMessage="Apply the code"
            description="Button that submits the OTC code"
          />
        </Button>

        <div className={styles.resendEmailBlock}>
          {isResetButtonEnabled ? (
            <Button
              kind="tertiary"
              loading={isResendButtonLoading}
              className={styles.resendEmailButton}
              onClick={onResendEmailClick}
            >
              <FormattedMessage
                id="authScreen.otcForm.resendEmailButton"
                defaultMessage="Resend email"
                description="Button that resends OTC email"
              />
            </Button>
          ) : (
            <span className={styles.resendEmailCountdown}>
              <FormattedMessage
                id="authScreen.otcForm.resendEmailCountdown"
                defaultMessage="Resend email in"
                description="Countdown timer for the resend email button"
              />{' '}
              {countdownResetTimer > 0 && (
                <b className={styles.resendEmailTimer}>
                  0:{countdownResetTimer.toString().padStart(secondsStringLength, '0')}
                </b>
              )}
            </span>
          )}
        </div>
      </form>

      <ReCaptchaDisclaimer />
    </div>
  );
};

export default OneTimeCodeForm;
