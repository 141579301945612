import React, { useEffect, useState, useContext } from 'react';
import { defaultLinks, fetchPersonalizedLinksWithAppUrl } from '../../utils/downloadLinks';

const DownloadLinksContext = React.createContext(defaultLinks);

interface DownloadLinksContextProviderProps {
  children: React.ReactNode;
  downloadToken?: string;
}

const DownloadLinksContextProvider = ({ children, downloadToken }: DownloadLinksContextProviderProps) => {
  const [links, setLinks] = useState(defaultLinks);

  useEffect(() => {
    if (downloadToken) {
      const appUrl = new URL('clearvpn://go/register');

      appUrl.searchParams.append('permanent_token', downloadToken);
      fetchPersonalizedLinksWithAppUrl(appUrl).then(setLinks);
    }
  }, [downloadToken]);

  return <DownloadLinksContext.Provider value={links}>{children}</DownloadLinksContext.Provider>;
};

export default DownloadLinksContextProvider;

export const useDownloadLinks = () => {
  return useContext(DownloadLinksContext);
};
