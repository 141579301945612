import React, { useEffect, useState } from 'react';
import t from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Loader from '../../common/Loader';
import alertExclamationIcon from '../../../../assets/icons/alert-icon.svg';
import closeDialogIcon from '../../../../assets/icons/close-dialog-icon.svg';
import styles from './UnlinkDeviceDialog.scss';

const UnlinkDeviceDialog = ({ onUnlink, onCancel, error, visible }) => {
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, [error]);

  const onUnlinkClick = () => {
    setLoading(true);
    onUnlink().finally(() => setLoading(false));
  };

  const onClose = () => {
    setLoading(false);
    onCancel();
  };

  return (
    <div className={styles.overlay} hidden={!visible}>
      <div className={`${styles.dialog} ${error ? styles.dialogWithError : ''}`}>
        <button className={styles.closeDialogButton} onClick={onClose}>
          <img src={closeDialogIcon} width="14" height="14" alt="" />
        </button>

        <img src={alertExclamationIcon} className={styles.alertIcon} width="48" height="48" alt="" />

        <h3 className={styles.title}>
          <FormattedMessage
            id="profile.deviceManagement.unlinkModal.title"
            defaultMessage="Are you sure you want to unlink your device?"
            description="Title of the modal to remove a device from the account"
          />
        </h3>

        <div className={styles.buttonsLine}>
          <button className={styles.goBackButton} onClick={onClose}>
            <FormattedMessage
              id="profile.deviceManagement.unlinkModal.cancelButton"
              defaultMessage="I changed my mind"
              description="Button that closes unlink device modal"
            />
            <span role="img" aria-label="Relaxed emoji">
              😊
            </span>
          </button>

          <button className={styles.cancelButton} onClick={onUnlinkClick}>
            {isLoading ? (
              <Loader size={20} />
            ) : (
              <>
                <FormattedMessage
                  id="profile.deviceManagement.unlinkModal.confirmButton"
                  defaultMessage="Unlink anyway"
                  description="Button that unlinks device and the account"
                />
                <span role="img" aria-label="Cry emoji">
                  😢
                </span>
              </>
            )}
          </button>
        </div>

        {error ? <span className={styles.error}>{error}</span> : null}
      </div>
    </div>
  );
};

UnlinkDeviceDialog.propTypes = {
  onUnlink: t.func.isRequired,
  onCancel: t.func.isRequired,
  error: t.string,
  visible: t.bool,
};

export default UnlinkDeviceDialog;
