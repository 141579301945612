import { FormattedMessage } from 'react-intl';
import { type UserProfile } from '../../../utils/api/schema/profileSchema';
import styles from './AccountDetails.scss';

const UnknownEmailPlaceholder = () => (
  <FormattedMessage
    id="profile.accountDetails.unknownEmail"
    defaultMessage="Unknown Email"
    description="Placeholder, for when don’t know user’s email, i.e. signed up via social network"
  />
);

interface AccountDetailsProps {
  profile: UserProfile;
}

const AccountDetails = ({ profile }: AccountDetailsProps) => {
  const { is_email_set: isEmailSet } = profile;

  return (
    <section className={styles.wrapper}>
      <h3 className={styles.header}>
        <FormattedMessage
          id="profile.accountDetails.title"
          defaultMessage="Account Details"
          description="Title of the profile section containing general user’s info, like name, pic, email"
        />
      </h3>

      <div className={styles.section}>
        <div className={styles.nameBlock}>
          <div className={styles.nameWrapper} key="email">
            <span className={styles.name}>{isEmailSet ? profile.email : <UnknownEmailPlaceholder />}</span>
            <span className={styles.nameFullView}>{isEmailSet ? profile.email : <UnknownEmailPlaceholder />}</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AccountDetails;
