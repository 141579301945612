import React, { useState, useEffect } from 'react';
import t from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Loader from '../../common/Loader';
import { sendGeoIpRequest } from '../../../utils/api/geoIpApi';
import { sendCancelSubscriptionRequest } from '../../../utils/api/storeApi';
import { resolvePlatform } from '../../../utils/platformResolver';
import alertExclamationIcon from '../../../../assets/icons/alert-icon.svg';
import closeDialogIcon from '../../../../assets/icons/close-dialog-icon.svg';
import styles from './UnsubscribeDialogs.scss';

const parseTypeFormHiddenFields = (profile, countryCode) => {
  const hasDevice = (platform) => (profile.devices.some((d) => d.platform === platform) ? 'yes' : 'no');

  return (
    `has_devices=${profile.devices.length > 0 ? 'yes' : 'no'},email=${profile.email},country=${countryCode},` +
    `user_id=${profile.account_id},android=${hasDevice('android')},ios=${hasDevice('ios')},` +
    `windows=${hasDevice('windows')},macos=${hasDevice('macos')},req_device=${resolvePlatform()}`
  );
};

const UnsubscribeDialog = ({ profile, onCancel, error, visible }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSurveyShown, setSurveyShown] = useState(false);
  const [hasCancelled, setHasCancelled] = useState(false);

  const [typeFormHiddenFields, setTypeFormHiddenFields] = useState('');

  useEffect(() => {
    sendGeoIpRequest().then((res) => {
      console.log(parseTypeFormHiddenFields(profile, res.data.country.iso_code));
      setTypeFormHiddenFields(parseTypeFormHiddenFields(profile, res.data.country.iso_code));
    });
  }, []);

  const onUnsubscribeClick = () => {
    setIsLoading(true);
    setSurveyShown(true);
    setTimeout(() => window.tf.load(), 0);

    sendCancelSubscriptionRequest(profile.subscription.id).then(() => setHasCancelled(true));
  };

  const onCloseButtonClick = () => {
    onCancel();
    if (hasCancelled) {
      location.reload();
    }
  };

  return (
    <div className={styles.overlay} hidden={!visible}>
      <div className={`${styles.dialog} ${error ? styles.dialogWithError : ''}`}>
        <button className={styles.closeDialogButton} onClick={onCloseButtonClick}>
          <img src={closeDialogIcon} width="14" height="14" alt="" />
        </button>

        <div>
          <img src={alertExclamationIcon} className={styles.alertIcon} width="48" height="48" alt="" />

          {!isSurveyShown && (
            <h3 className={styles.title}>
              <FormattedMessage
                id="profile.billing.unsubModal.title.initial"
                defaultMessage="Are you sure you want to cancel your subscription?"
                description="Title of the cancel subscription modal, shown before user confirmed cancellation"
              />
            </h3>
          )}

          <div className={styles.subtitle}>
            {isSurveyShown ? (
              <>
                {hasCancelled ? (
                  <FormattedMessage
                    id="profile.billing.unsubModal.subtitle.canceled"
                    defaultMessage="We’ve cancelled your subscription."
                    description="Subtitle of the cancel subscription modal, shown after subscription has been canceled"
                  />
                ) : (
                  <FormattedMessage
                    id="profile.billing.unsubModal.subtitle.pending"
                    defaultMessage="We’re cancelling your subscription."
                    description="Subtitle of the cancel subscription modal, shown while cancellation request is pending"
                  />
                )}
                <br />
                <FormattedMessage
                  id="profile.billing.unsubModal.subtitle.surveyInvite"
                  defaultMessage="Please let us know what went wrong by filling out a short survey below."
                  description="Part of a subtitle of the cancel subscription modal, invites user to fill out the feedback form below"
                />
              </>
            ) : (
              <FormattedMessage
                id="profile.billing.unsubModal.subtitle.initial"
                defaultMessage="Premium subscription gives access to all shortcuts, including “Unlock streaming & entertainment” and “Change your location”."
                description="Subtitle of the cancel subscription modal, shown before user confirmed cancellation"
              />
            )}
          </div>
        </div>

        {isSurveyShown ? (
          <div
            className="typeform-widget"
            style={{ width: '100%', height: '450px', marginTop: '16px' }}
            data-tf-widget="Sz8Ja2uR"
            data-tf-iframe-props="title=Cancel feedback"
            data-tf-medium="snippet"
            data-tf-hidden={typeFormHiddenFields}
          />
        ) : null}

        {!hasCancelled && (
          <div className={styles.buttonsLine}>
            <button className={styles.goBackButton} onClick={onCancel}>
              <FormattedMessage
                id="profile.billing.unsubModal.cta.close"
                defaultMessage="I changed my mind"
                description="Button of the cancel modal, closes the modal"
              />
              <span role="img" aria-label="Relaxed emoji">
                😊
              </span>
            </button>
            <button className={styles.cancelButton} onClick={onUnsubscribeClick}>
              {isLoading ? (
                <Loader size={20} />
              ) : (
                <>
                  <FormattedMessage
                    id="profile.billing.unsubModal.cta.cancel"
                    defaultMessage="Cancel anyway"
                    description="Button of the cancel modal, cancels subscription"
                  />
                  <span role="img" aria-label="Cry emoji">
                    😢
                  </span>
                </>
              )}
            </button>
          </div>
        )}

        {error ? <span className={styles.error}>{error}</span> : null}
      </div>
    </div>
  );
};

UnsubscribeDialog.propTypes = {
  profile: t.object.isRequired,
  onCancel: t.func.isRequired,
  error: t.string,
  visible: t.bool,
};

export default UnsubscribeDialog;
