import { trackProfileEvent, trackSetPasswordEvent } from './eSputnikAnalytics';

const trackProfile = (profile) => {
  return trackProfileEvent(profile).then(() => profile);
};

const trackSetPassword = (profile) => {
  return trackSetPasswordEvent(profile);
};

export { trackProfile, trackSetPassword };
