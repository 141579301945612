import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useQueryAnalytics } from '../../utils/analyticsHooks';
import { sendPermTokenRequest } from '../../utils/api/accountApi';
import { sendProfileRequest } from '../../utils/api/profileApi';
import { sendPaymentLinkRequest, sendAccountCouponRequest } from '../../utils/api/storeApi';
import { getAccessToken, logOut, saveTokens } from '../../utils/auth';
import { useRedirectUrl } from '../../utils/redirectAfterLogin';
import { registerReferralCode } from '../../utils/referralSignUp';
import { interceptAuthFromOrder } from '../../utils/authInterceptor';
import { isReferralBlockAvailable } from '../../utils/billingInfoParser';
import { useDownloadToken } from '../../utils/downloadToken';
import { useLocale } from '../lang/LocalizationProvider';
import { trackProfile } from '../../services/analytics/analyticsProvider';
import Loader from '../common/Loader';
import { getParsedParams, paramsKeys } from '../../utils/consts/params';
import { type UserProfile } from '../../utils/api/schema/profileSchema';
import { handleAuthSuccessEvents } from '../../utils/analytics/authEvents';
import { isApiError } from '../../utils/api/clearApiClient';
import { errorParams } from '../../utils/consts/errorParams';
import AccountDetails from './topRow/AccountDetails';
import DeviceManagement from './DeviceManagement';
import Notifications from './Notifications';
import FailedRenewBubble from './floating/FailedRenewBubble';
import DownloadModal from './floating/DownloadModal';
import BillingInfo from './BillingInfo';
import DiiaBlock from './DiiaBlock';
import ReferralBlock from './ReferralBlock';
import DownloadLinksContextProvider from './DownloadLinksContext';
import AccountManagement from './AccountManagement';
import styles from './ProfileContainer.scss';

const ProfileContainer = () => {
  const history = useHistory();

  const { PERMANENT_TOKEN, ACCESS_TOKEN, REFRESH_TOKEN, REGISTRATION_SOURCE, ADD_NEW_DEVICE } = getParsedParams();

  const [profile, setProfile] = useState<UserProfile | undefined>();
  const [premiumLink, setPremiumLink] = useState('');
  const [coupon, setCoupon] = useState<string | undefined>();
  const [isLoading, setLoading] = useState(true);

  useQueryAnalytics();

  const { resolveRedirectInProfile } = useRedirectUrl();

  const [isDeviceModalOpen, setDeviceModalOpen] = useState(false);
  const closeDeviceModal = () => {
    setDeviceModalOpen(false);
  };

  const { downloadToken, refreshDownloadToken } = useDownloadToken();

  useEffect(() => {
    const fetchProfile = () => {
      resolveRedirectInProfile();

      refreshDownloadToken();

      return registerReferralCode()
        .then(() =>
          Promise.all([
            sendPaymentLinkRequest().then((data) => {
              setPremiumLink(data.data.link);
            }),
            sendAccountCouponRequest().then((res) => {
              setCoupon(res.data.coupon);
            }),
            sendProfileRequest(true).then(
              (data) => {
                setProfile(data.profile);

                handleAuthSuccessEvents(data.profile);

                if (REGISTRATION_SOURCE === 'web_funnel' || ADD_NEW_DEVICE || data.profile.devices.length === 0) {
                  setDeviceModalOpen(true);

                  trackProfile(data.profile);
                }

                if (data.profile.marked_for_termination) {
                  window.location.href = `/enter?error=${errorParams.accountTerminated}`;
                }

                window.Intercom?.('update', {
                  email: data.profile.email,
                  name: data.profile.full_name,
                  user_id: data.profile.account_id,
                });
              },
              (error) => {
                if (
                  isApiError(error) &&
                  error.response?.data.code === 3 &&
                  error.response.data.message === 'AccountDeleted'
                ) {
                  window.location.href = `/enter/${paramsKeys.ERROR}=${errorParams.accountTerminated}`;
                  throw error;
                } else {
                  console.error(error);
                  window.location.href = '/h/error';
                }
              },
            ),
          ]),
        )
        .then(() => {
          setLoading(false);
        });
    };

    if (PERMANENT_TOKEN) {
      // if perma token is present exchanging it for the access and refresh ones
      sendPermTokenRequest(PERMANENT_TOKEN)
        .then(interceptAuthFromOrder)
        .then(fetchProfile)
        .catch(() => {
          console.log('Redirecting from /perm-token catch on perm');
          logOut();
        });
    } else if (ACCESS_TOKEN && REFRESH_TOKEN) {
      // saving tokens to cookies, if present
      saveTokens(ACCESS_TOKEN, REFRESH_TOKEN);
      console.log('Saving tokens, access:', getAccessToken());
      if (!interceptAuthFromOrder()) {
        history.replace('/profile');
        fetchProfile().catch((error: unknown) => {
          console.error(error);
          console.log('Redirecting from access-token catch on tokens');
          logOut();
        });
      }
    } else {
      interceptAuthFromOrder();
      fetchProfile().catch((error: unknown) => {
        console.error(error);
      });
    }
  }, []);

  const isRenewFailed = profile?.state === 'billing_retry_period' || profile?.state === 'grace_period';
  const isAnonAccount = profile?.email.endsWith('@anonymous.device') ?? false;

  const { selectedLocalization } = useLocale();
  const isReferralBlockShown = !isLoading && !isAnonAccount && isReferralBlockAvailable(profile, selectedLocalization);

  return (
    <DownloadLinksContextProvider downloadToken={downloadToken}>
      <div className={styles.container}>
        {isRenewFailed ? <FailedRenewBubble /> : null}

        <div className={styles.box}>
          <div className={styles.headline}>
            <div className={styles.headlineContainer}>
              <div className={`${styles.headlineSlider} ${isAnonAccount ? styles.headlineSliderAnonAccount : ''}`}>
                <h2>
                  <FormattedMessage
                    id="profile.title"
                    defaultMessage="Your Account"
                    description="Title of the profile page"
                  />
                </h2>
                <h2>
                  <FormattedMessage
                    id="profile.title.anonymous"
                    defaultMessage="Anonymous Account"
                    description="Title of the profile page for an anonymous user"
                  />
                </h2>
              </div>
            </div>
          </div>

          {isLoading || !profile ? (
            <Loader colored centered size={30} />
          ) : (
            <div className={styles.wrapper}>
              <AccountDetails profile={profile} />
              <BillingInfo profile={profile} premiumLink={premiumLink} coupon={coupon} />
              <DiiaBlock profile={profile} />
              <DeviceManagement
                devices={profile.devices}
                downloadToken={downloadToken}
                requestNewDownloadToken={refreshDownloadToken}
                isSetappSubscription={profile.type === 'setapp'}
                onAddNewDeviceClick={() => {
                  setDeviceModalOpen(true);
                }}
              />
              {isReferralBlockShown ? <ReferralBlock profile={profile} /> : null}
              {!isAnonAccount && <Notifications userSettings={profile.user_settings} />}
              <AccountManagement />
            </div>
          )}
        </div>
      </div>

      {!isLoading && (
        <DownloadModal
          isOpen={isDeviceModalOpen}
          downloadToken={downloadToken}
          requestNewDownloadToken={refreshDownloadToken}
          onClose={closeDeviceModal}
        />
      )}
    </DownloadLinksContextProvider>
  );
};

export default ProfileContainer;
