import { useState, useEffect } from 'react';
import { sendCreatePermanentTokenRequest } from './api/accountApi';

const TOKEN_TIMEOUT_MS = 4.9 * 60 * 1000; // ~ almost 5 minutes in milliseconds

/**
 * React hook to manage state and refreshes of `perm_token` attached to personalized download links.
 *
 * The token is strictly single use,
 * so if you have a way of knowing that it has been used (i.e. user has clicked a download link with it),
 * you should refresh it with `refreshDownloadToken()`.
 * @returns {{isLoadingDownloadToken: boolean, downloadToken: string, refreshDownloadToken: () => Promise<void> }}
 */
const useDownloadToken = () => {
  const [downloadToken, setDownloadToken] = useState('');

  const [isLoadingDownloadToken, setLoadingDownloadToken] = useState(false);

  const [refreshTimeout, setRefreshTimeout] = useState();
  const requestDownloadToken = () => {
    if (isLoadingDownloadToken) {
      return;
    }
    setLoadingDownloadToken(true);
    if (refreshTimeout) {
      clearTimeout(refreshTimeout);
    }

    sendCreatePermanentTokenRequest()
      .then(
        (res) => {
          setDownloadToken(res);
          setLoadingDownloadToken(false);
        },
        (error) => {
          console.error('Fetching /perm-token failed', error);
          setLoadingDownloadToken(false);
        },
      )
      .finally(() => {
        setRefreshTimeout(setTimeout(requestDownloadToken, TOKEN_TIMEOUT_MS));
      });
  };

  useEffect(
    () => () => {
      if (refreshTimeout) {
        clearTimeout(refreshTimeout);
      }
    },
    [],
  );

  return {
    downloadToken,
    isLoadingDownloadToken,
    refreshDownloadToken: requestDownloadToken,
  };
};

export { useDownloadToken };
