import React from 'react';
import t from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styles from './ReferralInstructions.scss';

const ReferralInstructions = ({ className }) => (
  <aside className={`${styles.instructions} ${className}`}>
    <h4 className={styles.instructionsTitle}>
      <FormattedMessage
        id="profile.referral.instructions.title"
        defaultMessage="How program works"
        description="Title of the block explaining how the referral system works"
      />
    </h4>

    <ol>
      <li>
        <FormattedMessage
          id="profile.referral.instructions.step1"
          defaultMessage="Invite up to 5 people to purchase ClearVPN."
          description="First step of the referral instructions"
        />
      </li>
      <li>
        <FormattedMessage
          id="profile.referral.instructions.step2"
          defaultMessage="Each friend gets 20% off their first subscription payment."
          description="Second step of the referral instructions"
        />
      </li>
      <li>
        <FormattedMessage
          id="profile.referral.instructions.step3"
          defaultMessage="Get up to 90% off your first Premium payment."
          description="Third step of the referral instructions"
        />
      </li>
    </ol>
  </aside>
);

ReferralInstructions.propTypes = {
  className: t.string,
};

export default ReferralInstructions;
