import { z } from 'zod';

const deviceSchema = z.object({
  id: z.string(),
  model: z.string(),
  name: z.string(),
  platform: z.string(),
});

const profileState = [
  'unknown_profile_state',
  'new_account',
  'active',
  'canceled',
  'expired',
  'billing_retry_period',
  'grace_period',
  'trial',
] as const;

const profileType = [
  'unknown_profile_type',
  'free',
  'subscription',
  'pay_as_you_go',
  'promo',
  'setapp',
  'guest',
  'diia',
] as const;

/*
  TODO: once `zod` merges this PR: https://github.com/colinhacks/zod/pull/2234
  replace child properties `nullable()` with parent object `partial()` that allows `null` instead of `undefined`
*/

const profileSchema = z
  .object({
    account_id: z.string(),
    device_limit: z.number().int(),
    devices: z.array(deviceSchema),
    diia_verified: z.boolean(),
    email: z.string(),
    email_can_be_verified: z.boolean(),
    email_verified: z.boolean(),
    full_name: z.string().optional(),
    is_email_set: z.boolean(),
    is_password_set: z.boolean(),
    locale: z.string(),
    marked_for_termination: z.boolean(),
    pay_as_you_go_profile: z
      .object({
        balance: z.string(),
        expiration_date: z.string().datetime({ offset: true }).nullable(),
      })
      .optional(),
    payment_data: z
      .object({
        payment_method: z
          .object({
            can_edited: z.boolean(),
            card_masked_number: z.string(),
            card_type: z.string(),
            payment_source_id: z.string(),
            paypal_email_masked: z.string(),
            vendor: z.string(),
          })
          .optional(),
        payment_method_exists: z.boolean(),
        payment_status: z.string(),
        payment_status_msg: z.string(),
      })
      .optional(),
    picture: z.string().nullish(),
    referral: z.object({
      referral_url: z.string().url(),
      referrals_count: z.number().int(),
      referrals_limit: z.number().int(),
      referrer_id: z.string().optional(),
    }),
    registration_date: z.string().datetime({ offset: true }),
    state: z.enum(profileState),
    subscription: z
      .object({
        billing_date: z.string().datetime({ offset: true }).nullable(),
        end_grace_period_date: z.string().datetime({ offset: true }).nullable(),
        expiration_date: z.string().datetime({ offset: true }).nullable(),
        hasActiveSubscription: z.boolean(),
        id: z.string(),
        name: z.string(),
        price: z
          .object({
            currency: z.string(),
            amount: z.string(),
          })
          .nullable(),
        sku: z.string(),
        subscriptionCancellable: z.boolean(),
        subscriptionRenewable: z.boolean(),
        vendor: z.enum(['unknown', 'stripe', 'apple', 'android', 'platform_payment', 'promocode', 'chargebee', 'diia']),
      })
      .partial()
      .optional(),
    type: z.enum(profileType),
    user_settings: z.object({ newsletters_enabled: z.boolean() }),
    vpn_user_credentials: z.object({ login: z.string(), password: z.string() }).optional(),
  })
  .passthrough();

export type UserProfile = z.infer<typeof profileSchema>;

export const profileReponseSchema = z.object({ profile: profileSchema });
export type ProfileAPIResponse = z.infer<typeof profileReponseSchema>;
