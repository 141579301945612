import { FormattedMessage } from 'react-intl';
import Footer from '../Footer/Footer';
import { Button } from '../common/Button';
import LocalizationSelect from '../common/LocalizationSelect';
import { logOut } from '../../utils/auth';
import Logo from '../../../assets/logo-long.png';
import confettiImage from '../../../assets/confetti.svg';
import styles from './ProfileLayout.scss';

interface ProfileLayoutProps {
  children: React.ReactNode;
  withConfetti?: boolean;
}

const ProfileLayout = ({ children, withConfetti = false }: ProfileLayoutProps) => (
  <div className={styles.container}>
    {withConfetti ? (
      <div className={styles.confettiWrapper}>
        <img src={confettiImage} alt="" className={styles.confetti} width="1210" height="110" />
      </div>
    ) : null}

    <main className={styles.wrapper}>
      <header className={styles.header}>
        <a href="https://clearvpn.com/" className={styles.logo}>
          <img src={Logo} width="119" height="32" alt="" />
        </a>

        <div className={styles.headerButtons}>
          <LocalizationSelect />

          <Button kind="secondary" className={styles.logOutButton} onClick={logOut}>
            <FormattedMessage
              id="profile.accountDetails.logOut"
              defaultMessage="Log out"
              description="Log out button, user will be dropped back to the login page"
            />
          </Button>
        </div>
      </header>

      {children}
    </main>

    <Footer />
  </div>
);

export default ProfileLayout;
