import React, { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { browserDeeplink } from '../../../../lib/browser-deeplink';
import EnterLayout from '../../layout/EnterLayout';
import Alert from '../../common/Alert';
import { resolvePlatform, isMobile } from '../../../utils/platformResolver';
import { defaultLinks, fetchPersonalizedLinks } from '../../../utils/downloadLinks';
import { logDownloadStarted } from '../../../utils/analytics/downloadEvents';
import { appIdentity } from '../../../utils/consts/appIdentity';
import { paramsKeys } from '../../../utils/consts/params';

const createAppLink = (): string => {
  const params = new URLSearchParams(window.location.search);

  const codeParam = params.get(paramsKeys.OTC_CODE);
  const appIdentityParam = params.get(paramsKeys.APP_IDENTITY);

  let appBaseUrl = 'clearvpn://';

  if (appIdentityParam === appIdentity.MACOS_SITE) {
    appBaseUrl = 'clearvpnsite://';
  } else if (appIdentityParam === appIdentity.MACOS_MAS) {
    appBaseUrl = 'clearvpnmas://';
  }

  return `${appBaseUrl}go/auth?otc=${codeParam}`;
};

const OTCForApps = () => {
  // this must be correct on the FIRST RENDER, or `autoClickAction` will brake app login
  const [appLink] = useState(createAppLink());

  const [isMobileLink] = useState(isMobile());

  const [downloadLink, setDownloadLink] = useState(defaultLinks[resolvePlatform() || 'ios']);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search).toString();

    fetchPersonalizedLinks(params).then((downloadLinks) => {
      setDownloadLink(downloadLinks[resolvePlatform() || 'ios']);
    });
  }, []);

  const [hasFailedToOpenApp, setHasFailedToOpenApp] = useState(false);
  const onClick = () => {
    console.log('Go to App URL:', appLink);
    browserDeeplink(appLink).catch((error: unknown) => {
      console.error('Deeplink did’t open', error);
      setHasFailedToOpenApp(true);
    });
  };

  const intl = useIntl();

  return (
    <EnterLayout verticalPaddings={[90, 60]}>
      <Alert
        autoClickAction
        href={isMobileLink ? appLink : undefined}
        icon="success"
        title={intl.formatMessage({
          id: 'toApp.title',
          defaultMessage: 'Open ClearVPN app',
          description: 'Title of the page, that opens up a predefined screen of the app',
        })}
        actionTitle={intl.formatMessage({
          id: 'redirectToApp.cta',
          defaultMessage: 'Go to App',
          description: 'Button that opens up ClearVPN app',
        })}
        footerChild={
          hasFailedToOpenApp ? (
            <FormattedMessage
              id="redirectToApp.downloadApp"
              defaultMessage="It seems, you don’t have the app installed yet, please, <link>download here.</link>"
              description="Banner on `To app` page shown, when user can't open the deeplink, because they does’t have it installed"
              values={{
                link: (chunk: string) => (
                  <a
                    href={downloadLink}
                    target={isMobile() ? '_blank' : '_self'}
                    rel="noreferrer"
                    onClick={() => {
                      logDownloadStarted(resolvePlatform());
                    }}
                  >
                    {chunk}
                  </a>
                ),
              }}
            />
          ) : undefined
        }
        onClick={isMobileLink ? undefined : onClick}
      />
    </EnterLayout>
  );
};

export default OTCForApps;
