import React, { useState, useEffect, useContext } from 'react';
import { IntlProvider } from 'react-intl';
import Cookies from 'js-cookie';
import { cookieDomain } from '../../utils/auth';

const LOCALE_COOKIE_KEY = 'locale';
const saveLocaleToCookie = (locale) =>
  Cookies.set(LOCALE_COOKIE_KEY, locale, {
    domain: cookieDomain,
    path: '/',
  });

const LOCALE_PARAM_KEY = 'lang';

const UKRAINIAN = 'uk';
const ENGLISH = 'en';
const GERMAN = 'de';
const FRENCH = 'fr';
const SPANISH = 'es';

const existingLocalizations = [UKRAINIAN, ENGLISH, GERMAN, FRENCH, SPANISH];

export const getLocaleFromCookie = () => {
  const cookieLocale = Cookies.get(LOCALE_COOKIE_KEY);

  if (cookieLocale && existingLocalizations.includes(cookieLocale)) {
    return cookieLocale;
  }
};

const resolveLocale = () => {
  const params = new URLSearchParams(window.location.search);
  const paramsLocale = params.get(LOCALE_PARAM_KEY);

  if (paramsLocale && existingLocalizations.includes(paramsLocale)) {
    saveLocaleToCookie(paramsLocale);

    return paramsLocale;
  }

  const cookieLocale = getLocaleFromCookie();

  if (cookieLocale) {
    return cookieLocale;
  }

  if (navigator.language.startsWith(UKRAINIAN)) {
    saveLocaleToCookie(UKRAINIAN);

    return UKRAINIAN;
  }
  saveLocaleToCookie(ENGLISH);

  return ENGLISH;
};

const LocaleContext = React.createContext();

const LocalizationProvider = ({ children }) => {
  const [selectedLocalization, setSelectedLocale] = useState(resolveLocale());
  const changeLocalization = (newLocale) => {
    if (existingLocalizations.includes(newLocale)) {
      setSelectedLocale(newLocale);
      saveLocaleToCookie(newLocale);
    }
  };

  const [messages, setMessages] = useState({});

  useEffect(() => {
    import(`/trans/${selectedLocalization}.json`)
      .then((localeModule) => setMessages(localeModule))
      .catch((error) => console.error(`Error with loading "${selectedLocalization}": ${error}`));
  }, [selectedLocalization]);

  const onProviderError = (err) => {
    if (err.code === 'MISSING_TRANSLATION') {
      return;
    }

    console.error(err);
  };

  return (
    <LocaleContext.Provider
      value={{
        selectedLocalization,
        availableLocalizations: existingLocalizations,
        changeLocalization,
      }}
    >
      <IntlProvider messages={messages} locale={selectedLocalization} defaultLocale={ENGLISH} onError={onProviderError}>
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};

export default LocalizationProvider;

export const useLocale = () => {
  const { selectedLocalization, availableLocalizations, changeLocalization } = useContext(LocaleContext);

  return { selectedLocalization, availableLocalizations, changeLocalization };
};
