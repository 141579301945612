import React from 'react';
import { FormattedMessage } from 'react-intl';
import dropDownArrow from '../../../../assets/icons/download-modal/dropdown-arrow.svg';
import styles from './PayPalDeprecationBanner.scss';

const PayPalDeprecationBanner = ({ onClick }) => (
  <button
    type="button"
    className={styles.banner}
    onClick={(e) => {
      e.preventDefault();
      onClick();
    }}
  >
    <h3 className={styles.title}>
      <FormattedMessage id="profile.billing.paypalDeprecation.title" defaultMessage="A Change in Payment Method" />
      <img src={dropDownArrow} width={16} height={16} alt="" />
    </h3>

    <p className={styles.text}>
      <FormattedMessage
        id="profile.billing.paypalDeprecation.text"
        defaultMessage="Unfortunately, we'll no longer support PayPal as a payment option.
            Please update your payment method before <b>December 28</b>."
        values={{
          b: (chunk) => <b>{chunk}</b>,
        }}
      />
    </p>
  </button>
);

export default PayPalDeprecationBanner;
