import React from 'react';
import t from 'prop-types';
import Loader from '../../common/Loader';
import styles from './Checkbox.scss';

/**
 * Checkbox component
 * @param {function} onChange
 * @param {?string} label
 * @param {?string} error
 * @param {?boolean} largeLabel
 * @param {?boolean} value
 * @param {?boolean} isLoading
 * @param {?boolean} isNoError
 * @param {?string} className
 * @returns {JSX.Element}
 */
const Checkbox = ({ onChange, label, error, largeLabel, value, isLoading, isNoError, className = '' }) => {
  const handleChange = () => onChange(!value);

  return (
    <div className={`${styles.container} ${className}`}>
      {isLoading ? (
        <Loader colored size={14} />
      ) : (
        <input type="checkbox" id="checkbox" checked={value} className={styles.input} onChange={handleChange} />
      )}

      <label
        htmlFor="checkbox"
        className={`${styles.label} ${largeLabel && styles.large} ${isLoading ? styles.noCheckbox : ''}`}
      >
        {label}
      </label>

      {!isNoError && <div className={styles.error}>{error}</div>}
    </div>
  );
};

Checkbox.propTypes = {
  onChange: t.func,
  label: t.string,
  error: t.string,
  largeLabel: t.bool,
  value: t.bool,
  isLoading: t.bool,
  isNoError: t.bool,
};

export default Checkbox;
