import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import t from 'prop-types';
import Loader from '../../common/Loader';
import { sendRenewSubscriptionRequest } from '../../../utils/api/storeApi';
import successIcon from '../../../../assets/icons/success.svg';
import closeDialogIcon from '../../../../assets/icons/close-dialog-icon.svg';
import styles from './UnsubscribeDialogs.scss';

const RenewDialog = ({ profile, isDialogShown, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const onRenewClick = () => {
    setIsLoading(true);
    sendRenewSubscriptionRequest(profile.subscription.id)
      .then(() => location.reload())
      .catch(() =>
        setError('Internal error. Please, try again later. If error occurs again please contact customer support.'),
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  return isDialogShown ? (
    <div className={styles.overlay}>
      <div className={`${styles.dialog} ${error ? styles.dialogWithError : ''}`}>
        <button className={styles.closeDialogButton} onClick={onClose}>
          <img src={closeDialogIcon} width="14" height="14" alt="" />
        </button>

        <img src={successIcon} className={styles.alertIcon} alt="" />

        <h3 className={styles.title}>
          <FormattedMessage
            id="profile.billing.renewModal.title"
            defaultMessage="Are you sure you want to renew your Premium subscription?"
            description="Title of the modal, where a user can renew an already cancelled subscription."
          />
        </h3>

        <div className={styles.subtitle}>
          <FormattedMessage
            id="profile.billing.renewModal.text"
            defaultMessage="Premium subscription gives access to all tools."
            description="Subtitle of the modal, where a user can renew an already cancelled subscription."
          />
        </div>

        <div className={styles.buttonsLine}>
          <button className={styles.goBackButton} onClick={onClose}>
            <FormattedMessage
              id="profile.billing.renewModal.backButton"
              defaultMessage="Go back"
              description="Back button of the modal, where a user can renew an already cancelled subscription; closes the modal."
            />
          </button>
          <button className={styles.cancelButton} onClick={onRenewClick}>
            {isLoading ? (
              <Loader size={20} />
            ) : (
              <FormattedMessage
                id="profile.billing.renewModal.renewButton"
                defaultMessage="Renew Premium"
                description="Submit button of the modal, where a user can renew an already cancelled subscription."
              />
            )}
          </button>
        </div>

        {error ? <span className={styles.error}>{error}</span> : null}
      </div>
    </div>
  ) : null;
};

RenewDialog.propTypes = {
  profile: t.object.isRequired,
  isDialogShown: t.bool,
  onClose: t.func.isRequired,
};

export default RenewDialog;
